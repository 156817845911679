.approver {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

            .modal-header {
                border: none;
                padding: 1.5rem 1.5rem 0 1.5rem;

                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .modal-body {
                .body {
                    gap: 1.5rem;
                    display: flex;
                    padding: 0 2rem;

                    .lineContainer {
                        gap: 18px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;

                        .circleContainer {
                            width: 36px;
                            height: 36px;
                            position: relative;

                            .cirle {
                                position: absolute;
                                width: 36px;
                                height: 36px;
                            }
                        }

                        .arrowContainer {
                            width: 29px;
                            height: 100px;
                            position: relative;

                            .arrow {
                                top: -18px;
                                right: -15px;
                                position: absolute;
                            }

                            &::after {
                                width: 2px;
                                content: '';
                                height: 5rem;
                                position: absolute;
                                background-color: #c7c7c7;
                                right: 0;
                                left: -1px;
                                margin: auto;
                                top: -65px;
                            }

                            &.short {
                                &::after {
                                    height: 0;
                                }
                            }
                        }
                    }

                    .cardContainer {
                        gap: 1.5rem;
                        display: flex;
                        padding: 1rem;
                        flex-direction: column;


                        .card {
                            display: flex;
                            flex: 0 0 2rem;
                            max-width: 20rem;
                            position: relative;
                            flex-direction: row;
                            border-radius: 10px;
                            background: #F0F0F0;

                            .close {
                                top: 5px;
                                right: 5px;
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                                position: absolute;
                            }

                            .drag {
                                padding: 1rem;
                                display: flex;
                                align-items: center;
                                background: #E7E7E7;
                                justify-content: center;
                                border-radius: 10px 0px 0px 10px;

                                &.declined,
                                &.rejected {
                                    background: #FF8E8E;
                                }

                                &.approved {
                                    background: #03A900;
                                }
                            }

                            .info {
                                flex: 1;
                                gap: 1rem;
                                height: 6rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                padding: 1rem 1.5rem 1rem 1rem;

                                p {
                                    margin: 0;
                                    font-size: 15px;
                                    color: #022BFF;
                                    font-weight: 500;
                                    letter-spacing: 0.095em;
                                }

                                input {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}