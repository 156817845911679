// GLOBAL SCSS.
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

$mainColor: #168a3e;
$lightMainColor: #97e09b;

html * {
  font-family: 'Poppins', sans-serif;
}

input.form-control {
  &:focus {
    border-color: $mainColor;
    box-shadow: 0 0 0 0.25rem $lightMainColor;
  }
}

.global-form-input {
  position: relative;

  input {
    padding: 20px 20px;
    border-radius: 38px;
    border: 0.8px solid #878787;
    background-color: #fff !important;

    &:-internal-autofill-selected {
      background-color: #fff !important;
    }

    &:focus,
    &:active {
      box-shadow: none;
      border-color: #022BFF;
    }

    &::placeholder {
      font-size: 13px;
    }
  }

  .eye-icon {
    margin: 0;
    width: 25px;
    right: 20px;
    bottom: 26px;
    height: 15px;
    margin: auto;
    cursor: pointer;
    position: absolute;

    &.hide {
      margin: 0;
      right: 20px;
      width: 25px;
      height: 19px;
      bottom: 21px;
    }
  }
}

.global-form-input-check {
  .form-check {
    margin: 0;
  }
}

.container-fluid {
  margin: auto;
  max-width: 1440px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 969px) {
  .global-form-input {
    .left-icon {
      height: 56px;
    }

    input {
      padding: 15px 15px;
    }

    .eye-icon.hide {
      bottom: 15px;
    }
  }
}