.container {
  @media only screen and (max-width: 969px) {
    padding: 0 !important;
  }
}

.forgotPassContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .forgotPassForm {
    gap: 1rem;
    height: 100%;
    margin: auto;
    flex: 0 0 35%;
    display: flex;
    padding: 2rem 3rem;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;

    img {
      width: 10rem;
      margin: 0 auto;
    }

    label {
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
    }

    .loginHeader {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h6 {
        margin: 0;
        font-size: 40px;
        font-size: 40px;
        color: #022BFF;
        font-weight: 500;
        line-height: 60px;
      }

      p {
        font-size: 13px;
        font-weight: 500;
        color: #575757;
      }
    }

    .formContainer {
      gap: 1rem;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .passwordCheck {
        gap: 10px;
        display: flex;
        align-content: center;

        ul {
          gap: 5px;
          margin: 0;
          padding: 0;
          display: flex;
          list-style: none;
          align-items: center;

          li {
            width: 35px;
            height: 10px;
            display: block;
            border-radius: 38px;

            &:nth-child(1) {
              background-color: #FF0000;
            }

            &:nth-child(2) {
              background-color: #FFD702;
            }

            &:nth-child(3) {
              background-color: #022BFF;
            }

            &:nth-child(4) {
              background-color: #03A900;
            }
          }
        }

        p {
          margin: 0;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .forgotPassword {
      font-size: 14px;
      color: #D1002A;
      font-weight: 500;
      text-align: right;
      text-decoration: none;
    }

    .marginContainer {
      gap: 10px;
      width: 100%;
      display: flex;
      margin: 1rem auto 0;
      flex-direction: column;

      button {
        width: 100%;
      }

      .submitButton {
        height: 66px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 38px;
        border-color: #022BFF;
        background-color: #022BFF;
      }

      .signup {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
        }

        .link {
          font-size: 12px;
          color: #D1002A;
          font-weight: 500;
          text-decoration: none;
        }

        .checkBox {
          display: flex;

          input {
            border: solid 1px #848484;

            &:checked[type=checkbox] {
              background-color: #848484 !important;
            }
          }

          p {
            font-size: 12px;

            span {
              font-size: 12px;
              color: #D1002A;
            }
          }
        }
      }

      .uploadId {
        margin: 0 0 1.5rem;

        label {
          margin: 0;
          padding: 0;
          width: 100%;
          display: flex;
          color: #fff;
          height: 37.6px;
          cursor: pointer;
          font-weight: 400;
          align-items: center;
          justify-content: center;
          border-radius: 0.375rem;
          background-color: #D1002A;
        }

        input {
          display: none;
        }
      }
    }
  }

  .imageContainer {
    height: 100%;
    flex: 0 0 65%;
    text-align: right;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .copyright {
    margin: 0;
    bottom: 25px;
    color: #878787;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    position: absolute;
  }

  @media (max-width: 1400px) {
    .forgotPassForm {}

    .imageContainer {}
  }

  @media (max-width: 1200px) {
    .forgotPassForm {
      flex: 1;
    }

    .imageContainer {
      flex: 2;

      img {
        width: 100%;
        height: 100%;
        object-position: left;
      }
    }
  }

  @media (max-width: 969px) {
    width: auto;

    .forgotPassForm {
      flex: 1 1;
      height: auto;
      margin: 0 3rem;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .161);

      .loginHeader {
        h6 {
          font-size: 1.4rem;
        }

        p {
          font-size: 16px;
          line-height: 1;
        }
      }

      .marginContainer {
        .submitButton {
          height: 56px;
        }

        .signup {
          gap: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
            padding: 0;
            font-size: 12px;
          }

          .link {
            font-size: 12px;
            color: #D1002A;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }

      .copyright {
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        text-align: center;
      }
    }

    .imageContainer {
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;

      img {
        top: 0;
        bottom: 0;
        z-index: -1;
        height: 100%;
        left: 0;
        width: 100%;
        position: absolute;
        object-position: right;
      }
    }
  }

  @media (max-width: 767px) {
    .forgotPassForm {
      margin: 0 1rem;
      padding: 1.5rem;
    }
  }
}