.approval {
  tbody {
    tr {
      td {
        font-size: 15px;
        font-weight: 600;
        color: #1E1E1E;
        vertical-align: middle;

        img {
          width: 5rem;
        }

        button.button {
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF;
          border-radius: 18px;
          background: #4462FF;
          border-color: #4462FF;
        }

        span {
          color: #fff;
          display: block;
          font-size: 13px;
          font-weight: 500;
          padding: 5px 14px;
          width: max-content;
          background: blue;
          text-align: center;
          border-radius: 18px;

          &.Pending {
            color: #D7B400;
            border: 1px solid #D7B400;
            background: rgba(215, 180, 0, 0.13);
          }

          &.InProgress {
            color: #002bc8;
            border: 1px solid #002bc8;
            background: #9b9aff;
          }

          &.rejected {
            color: #e50f0f;
            border: 1px solid #e50f0f;
            background: #d74a4a21;
          }

          &.Completed {
            color: #03A900;
            border: 1px solid #03A900;
            background: #3fe95821;
          }
        }
      }
    }
  }
}